/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

.autocomplete-container {
    box-shadow: none;
}

.autocomplete-container .input-container input {
    border: 1px solid #c6c6c6 !important;
    border-radius: 5px;
}

.autocomplete-container {
    box-shadow: none !important;
}

.autocomplete-container .input-container input {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjNjY2IiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+PGNpcmNsZSBjeD0iMTEiIGN5PSIxMSIgcj0iOCIvPjxwYXRoIGQ9Ik0yMSAyMWwtNC00Ii8+PC9zdmc+);
    width: 100% !important;
    box-sizing: border-box !important;
    position: relative !important;
    font-size: 16px !important;
    line-height: 1.5 !important;
    background-color: rgb(238, 238, 238) !important;
    border-width: 1px !important;
    border-style: solid !important;
    border-color: rgb(238, 238, 238) !important;
    border-image: initial !important;
    border-radius: 8px !important;
    padding: 12px 12px 12px 48px !important;
    flex: 1 1 0% !important;
    background-repeat: no-repeat !important;
    background-position: 12px center !important;
}

.toast-title,
.toast-close-button span {
    font-size: 15px !important;
    font-weight: bold !important;
}

.toast-container .ngx-toastr {
    font-size: 12px !important;
    font-weight: bold !important;
}

.toast-success {
    box-shadow: 0 5px 20px rgba(0, 0, 0, .5);
    background-color: #258927;
    border-color: #258927;
    color: #fff;
}

.toast-error {
    box-shadow: 0 5px 20px rgba(0, 0, 0, .5);
    background-color: #ea001e;
    border-color: #ea001e;
    color: #fff;
}

.toast-warning {
    box-shadow: 0 5px 20px rgba(0, 0, 0, .5);
    background-color: #fe9339;
    border-color: #fe9339;
    color: #fff;
}